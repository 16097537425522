import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import img_1 from '../assets/img/06.jpg';
import img_2 from '../assets/img/index2.jpg';
import img_3 from '../assets/img/index.jpg';
import img_4 from '../assets/img/program.jpg';
import img_5 from '../assets/img/03.jpg';

const ControlledCarousel: React.FC = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex: any) => {
        setIndex(selectedIndex);
    };

    return (
        <Carousel activeIndex={index} onSelect={handleSelect} translate='yes' interval={60000}>
            <Carousel.Item>
                <img className='d-block w-100 w-100-1' src={img_1}></img>
                <Carousel.Caption>
                    
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
                <img className='d-block w-100 w-100-1' src={img_2}></img>
                <Carousel.Caption>
                    {/* <h3><span>GRUPO</span>  ACSSI</h3>
                    <p>
                    Grupo ACSSI es especialista en el desarrollo de plataformas web y sitios web. Nuestro equipo ofrece servicios de mantenimiento preventivo y correctivo, así como asesoramiento tecnológico personalizado para empresas, negocios y personas físicas. Además, brindamos apoyo en redes y redes sociales, garantizando un acompañamiento integral y efectivo en el ámbito digital.
                    </p> */}
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
                <img className='d-block w-100 w-100-1' src={img_3}></img>
                <Carousel.Caption>
                    
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
                <img className='d-block w-100 w-100-1' src={img_4}></img>
                <Carousel.Caption>
                    
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
                <img className='d-block w-100 w-100-1' src={img_5}></img>
                <Carousel.Caption>
                    
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}

export default ControlledCarousel;