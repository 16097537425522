import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface ModalServiceProps {
    show: boolean;
    handleClose: () => void;
    imgSrc: string;
    previewLinkTitle: string;
}

const ModalService: React.FC<ModalServiceProps> = ({ show, handleClose, imgSrc, previewLinkTitle }) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{previewLinkTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img src={imgSrc} alt={previewLinkTitle} className="img-fluid" />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalService;
