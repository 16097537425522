import React from 'react';

interface CardServiceProps {
    icon: string;
    title: string;
    description: string;
    link: string;
}

const CardService: React.FC<CardServiceProps> = ({ icon, title, description, link }) => {
    return (
        <div className="col-xl-3 col-md-6 d-flex mt-3" >
            <div className="service-item position-relative">
                <i className="material-symbols-outlined">{icon}</i>
                {/* <h4><a href={link} className="stretched-link" >{title}</a></h4> */}
                <h4 className="stretched-link" >{title}</h4>
                <p>{description}</p>
            </div>
        </div>
    );
};

export default CardService;
