import React, { useState } from 'react';
import GalleryItem from '../GalleryItem';
import ModalService from '../Boostrap/ModalService';

interface GallerySectionProps {
    title: string;
    items: Array<{ imgSrc: string; imgAlt: string; previewLinkTitle: string; detailsLink: string; }>;
}
const GallerySection: React.FC<GallerySectionProps> = ({ title, items }) => {
    const [showModal, setShowModal] = useState(false);
    const [modalImgSrc, setModalImgSrc] = useState('');
    const [modalPreviewLinkTitle, setModalPreviewLinkTitle] = useState('');

    const handleShowModal = (imgSrc: string, previewLinkTitle: string) => {
        setModalImgSrc(imgSrc);
        setModalPreviewLinkTitle(previewLinkTitle);
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);
    return (
        <section className="gallery">
            <div className="container">
                <div className="section-header mt-5">
                    <h2>{title}</h2>
                    <p></p>
                </div>
            </div>
            <div className="container">
                <div className="row gy-4 justify-content-center">
                    {items.map((item, index) => (
                        <GalleryItem
                            key={index}
                            imgSrc={item.imgSrc}
                            imgAlt={item.imgAlt}
                            previewLinkTitle={item.previewLinkTitle}
                            detailsLink={item.detailsLink}
                            handleShowModal={handleShowModal}
                        />
                    ))}
                </div>

                {/* Modal Component */}
                <ModalService
                    show={showModal}
                    handleClose={handleCloseModal}
                    imgSrc={modalImgSrc}
                    previewLinkTitle={modalPreviewLinkTitle}
                />
            </div>
        </section>
    );
};
export default GallerySection;