import React from 'react';
import CardService from '../CardService';

interface ServiceItem {
    icon: string;
    title: string;
    description: string;
    link: string;
}

interface ServicesSectionProps {
    id: string;
    sectionTitle: string;
    services: ServiceItem[];
}

const ServicesSection: React.FC<ServicesSectionProps> = ({ id, sectionTitle, services }) => {
    return (
        <section id={id} className="services">
            <div className="container">
                <div className="section-header mt-5">
                    <h2>{sectionTitle}</h2>
                    <p></p>
                </div>
                <div className="row">
                    {services.map((service, index) => (
                        <CardService
                            key={index}
                            icon={service.icon}
                            title={service.title}
                            description={service.description}
                            link={service.link}
                            data-aos="fade-left"
        data-aos-anchor="#example-anchor"
        data-aos-offset="500"
        data-aos-duration="1500"
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default ServicesSection;
