import React, { useEffect, useRef } from 'react';

interface ParallaxSectionProps {
    image: string;
    children: React.ReactNode;
}

const ParallaxSection: React.FC<ParallaxSectionProps> = ({ image, children }) => {
    const parallaxRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleScroll = () => {
            if (parallaxRef.current) {
                const offset = window.pageYOffset;
                parallaxRef.current.style.backgroundPositionY = `${offset * 0.5}px`;
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="parallax" ref={parallaxRef} style={{ backgroundImage: `url(${image})` }}>
            {children}
        </div>
    );
};

export default ParallaxSection;
